var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reviewItemDiv" },
    [
      _c(
        "Card",
        { ref: "colHeight", staticStyle: { height: "100%" } },
        [
          _c(
            "Row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "Form",
                    {
                      ref: "queryForm",
                      attrs: { "label-width": 80, inline: "" },
                    },
                    [
                      _c(
                        "FormItem",
                        { attrs: { label: "体检编号" } },
                        [
                          _c("Input", {
                            attrs: {
                              placeholder: "请输入体检编号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryFrom.testNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryFrom, "testNum", $$v)
                              },
                              expression: "queryFrom.testNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "检查种类" } },
                        [
                          _c(
                            "Select",
                            {
                              staticStyle: { width: "185px" },
                              attrs: {
                                transfer: "",
                                clearable: "",
                                "label-in-value": "",
                              },
                              model: {
                                value: _vm.queryFrom.workStateCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryFrom, "workStateCode", $$v)
                                },
                                expression: "queryFrom.workStateCode",
                              },
                            },
                            _vm._l(
                              _vm.workStateCodeArr,
                              function (item, index) {
                                return _c(
                                  "Option",
                                  { key: index, attrs: { value: item.value } },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "姓名" } },
                        [
                          _c("Input", {
                            attrs: { placeholder: "请输入姓名", clearable: "" },
                            model: {
                              value: _vm.queryFrom.personName,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryFrom, "personName", $$v)
                              },
                              expression: "queryFrom.personName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "身份证号" } },
                        [
                          _c("Input", {
                            attrs: {
                              placeholder: "请输入身份证号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryFrom.idCard,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryFrom, "idCard", $$v)
                              },
                              expression: "queryFrom.idCard",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.drop
                        ? _c(
                            "FormItem",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "Select",
                                {
                                  staticStyle: { width: "185px" },
                                  attrs: { transfer: "", clearable: "" },
                                  model: {
                                    value: _vm.queryFrom.isPass,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryFrom, "isPass", $$v)
                                    },
                                    expression: "queryFrom.isPass",
                                  },
                                },
                                [
                                  _c("Option", { attrs: { value: "89" } }, [
                                    _vm._v("全部"),
                                  ]),
                                  _c("Option", { attrs: { value: "88" } }, [
                                    _vm._v("已总检"),
                                  ]),
                                  _c("Option", { attrs: { value: "87" } }, [
                                    _vm._v("待总检"),
                                  ]),
                                  _c("Option", { attrs: { value: "2" } }, [
                                    _vm._v("在体检"),
                                  ]),
                                  _c("Option", { attrs: { value: "1" } }, [
                                    _vm._v("未登记"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.drop
                        ? _c(
                            "FormItem",
                            { attrs: { label: "性别" } },
                            [
                              _c(
                                "Select",
                                {
                                  staticStyle: { width: "185px" },
                                  attrs: { transfer: "", clearable: "" },
                                  model: {
                                    value: _vm.queryFrom.sex,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryFrom, "sex", $$v)
                                    },
                                    expression: "queryFrom.sex",
                                  },
                                },
                                [
                                  _c("Option", { attrs: { value: "男" } }, [
                                    _vm._v("男"),
                                  ]),
                                  _c("Option", { attrs: { value: "女" } }, [
                                    _vm._v("女"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.drop
                        ? _c(
                            "FormItem",
                            { attrs: { label: "体检日期" } },
                            [
                              _c("DatePicker", {
                                staticStyle: { width: "185px" },
                                attrs: {
                                  transfer: "",
                                  clearable: false,
                                  placeholder: "请选择",
                                  type: "daterange",
                                },
                                on: { "on-change": _vm.registDateChange },
                                model: {
                                  value: _vm.selectDate,
                                  callback: function ($$v) {
                                    _vm.selectDate = $$v
                                  },
                                  expression: "selectDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.drop
                        ? _c(
                            "FormItem",
                            { attrs: { label: "单位名称" } },
                            [
                              _c(
                                "Select",
                                {
                                  staticStyle: { width: "185px" },
                                  attrs: { clearable: "", filterable: "" },
                                  model: {
                                    value: _vm.queryFrom.dept,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryFrom, "dept", $$v)
                                    },
                                    expression: "queryFrom.dept",
                                  },
                                },
                                _vm._l(_vm.unitArr, function (item, index) {
                                  return _c(
                                    "Option",
                                    {
                                      key: index,
                                      attrs: {
                                        value: item.name,
                                        label: item.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.drop
                        ? _c(
                            "FormItem",
                            { attrs: { label: "项目名称" } },
                            [
                              _c("Input", {
                                attrs: {
                                  placeholder: "请输入项目名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryFrom.portfolioProjectName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryFrom,
                                      "portfolioProjectName",
                                      $$v
                                    )
                                  },
                                  expression: "queryFrom.portfolioProjectName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FormItem",
                        {
                          staticClass: "br",
                          staticStyle: { "margin-left": "-35px" },
                        },
                        [
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.getReviewProjectPerson },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "drop-down",
                              on: { click: _vm.dropDown },
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                              _c("Icon", { attrs: { type: _vm.dropDownIcon } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            { attrs: { justify: "end" } },
            [
              _c("Table", {
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  loading: _vm.tableLoading,
                  border: "",
                  columns: _vm.columns,
                  sortable: "custom",
                  data: _vm.tableData,
                  "max-height": _vm.height,
                },
              }),
              _c(
                "Row",
                {
                  staticClass: "page",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c("Page", {
                    attrs: {
                      current: _vm.queryFrom.pageNumber,
                      total: _vm.total,
                      "page-size": _vm.queryFrom.pageSize,
                      "page-size-opts": [10, 20, 50, 100, 200, 500],
                      size: "small",
                      "show-total": "",
                      "show-elevator": "",
                      "show-sizer": "",
                      transfer: "",
                    },
                    on: {
                      "on-change": _vm.changePage,
                      "on-page-size-change": _vm.changePageSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }